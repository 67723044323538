@import '../../../styles/customMediaQueries.css';

.container {
  overflow: hidden;
}

.title {
  composes: h1 from global;
  composes: title from global;

  margin-top: 0;
  margin-bottom: 0;
}

.filteredSearches {
  display: flex;
  flex-direction: row;
  margin-top: 32px;
  justify-content: center;
  gap: 30px;
  /* height: 300px; */

  /* Auto-scroll effect */
  animation: scroll-left 30s linear infinite;
}

@keyframes scroll-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

/* Stop scrolling on hover */
.filteredSearches:hover {
  animation-play-state: paused;
}

.searchLink {
  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center; /* Center content horizontally */

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

@media (--viewportMedium) {
  /* .searchLink {
    margin-top: 0;
    margin-right: 40px;
    margin-bottom: 0;

    &:last-of-type {
      margin-right: 0;
    }
  } */
}

/* A wrapper for a responsive image that holds it's aspect ratio */
.imageWrapper {
  position: relative;
  width: 28.85vw;
  height: 28.85vw;

  border-radius: 50%;
  border: 2px solid var(--marketplaceColorLight);
  overflow: hidden;

  transition: var(--transitionStyleButton);

  &:hover {
    transform: scale(1.02);
    box-shadow: var(--boxShadowSectionLocationHover);
  }

  @media (--viewportLarge) {
    width: 8.15vw;
    height: 8.15vw;
  }
}

/* An image wrapper that defines the images aspect ratio */
.aspectWrapper {
  padding-bottom: calc(6 / 13 * 100%); /* 13:6 Aspect Ratio */

  @media (--viewportMedium) {
    padding-bottom: calc(2 / 3 * 100%); /* 3:2 Aspect Ratio */
  }
}

.searchImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  object-fit: contain;
}

.linkText {
  width: 28.85vw;
  height: 28.85vw;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 50%; /* This makes the shape circular */
  border: 1px solid green;
  padding: 30px;
  composes: h2 from global;
  color: var(--airgoodsBlack);
  /* margin-top: 15px; */
  /* margin-bottom: 0; */
  margin: 0;
  line-height: 1.6rem;
  font-size: 1.4rem;

  overflow: hidden;

  @media (--viewportLarge) {
    width: 8.15vw;
    height: 8.15vw;
    font-size: 1rem;
    line-height: 1.2rem;
  }
}

.searchName {
  color: var(--matterColor);
  text-transform: capitalize;
  font-weight: var(--fontWeightRegular);
}
