@import '../../../styles/customMediaQueries.css';

.header{
  display: flex;
}

.title {
  composes: h1 from global;
  composes: title from global;

  width: fit-content;

  margin-top: 0;
  margin-bottom: 0;
}

.listingCards {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.listingCards {
  /* mobile */
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 24px;

  /* tablet and desktop */
  @media (--viewportMedium) {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    gap: 24px; /* Adjust spacing between cards */
    width: 100%;
  }
}

.listingCard {
  display: block;
  width: 100%; /* Full width on mobile */

  /* tablet */
  @media (--viewportMedium) {
    width: calc(40% - 36px); /* 33.33% of the parent's width minus gap */
    flex: 0 0 auto; /* Prevent shrinking */
  }

  /* desktop */
  @media (--viewportLarge) {
    width: 199px;
  }
}

.exploreLink {
  composes: h1 from global;
  composes: title from global;


  text-decoration: underline;
  /* Layout */
  display: flex;
  align-items: center;
  gap: 8px;

  width: fit-content;

  margin:0;
  margin-left: auto;
}

.exploreIcon {
  & g {
    stroke: var(--airgoodsBlack) ;
  }
}
