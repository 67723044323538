@import '../../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  /* Font */
  text-align: center;

  padding: 32px 24px;
  @media (--viewportLarge) {
    padding: 48px 24px;
  }
}

.title {
  /* Font */
  font-family: var(--fontFamilyTexGyreTermes);
  font-weight: var(--fontWeightRegular);
  color: var(--landingPageSellOnPetspaceSecondaryColor);

  margin-top: 0;
  margin-bottom: 24px;
}

.features {
  /* Layout */
  display: flex;
  flex-direction: column;
  gap: 12px;

  margin-bottom: 24px;
}

.feature {
  color: var(--landingPageSellOnPetspaceSecondaryColor);
}

.linkButton {
  composes: button buttonFont buttonText buttonBorders buttonColorsPrimary from global;

  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Style */
  background-color: var(--matterColorDark);
  border: 2px solid var(--matterColorLight);
  border-radius: var(--borderRadiusMedium);
  padding: 0;

  /* Size */
  height: 56px;
  min-height: 56px;
  max-width: 280px;
}
