@import '../../../styles/customMediaQueries.css';

:root {
  --landingPageServicesMainColor: #f7e0cf;
  --landingPageServicesSecondaryColor: #ff8c00;
}

.root {
  /* Style */
  background-color: var(--landingPageServicesMainColor);
  border-radius: var(--borderRadiusMedium);

  padding: 32px 24px;
  @media (--viewportLarge) {
    padding: 48px 24px;
  }
}

.service {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  max-width: 320px;
}

.services {
  /* Layout */
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;

  @media (--viewportMedium) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: 24px;
  }
}

.serviceIcon {
  fill: none;
}

.serviceHeading {
  /* Font */
  color: var(--landingPageServicesSecondaryColor);
  text-transform: capitalize;

  margin-top: 18px;
  margin-bottom: 12px;
}

.serviceText {
  margin-top: 0;
  margin-bottom: 0;

  &:nth-child(2) {
    margin-top: 8px;
  }
}
