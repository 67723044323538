@import '../../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  /* Font */
  text-align: center;

  padding: 32px 24px;
}

.title {
  composes: sectionIntroTitle from global;
}

.linkButton {
  composes: button buttonFont buttonText buttonBorders buttonColorsPrimary from global;

  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Style */
  background-color: var(--landingPageIntroSecondaryColor);
  border: 2px solid var(--landingPageIntroMainColor);
  border-radius: 30px;
  padding: 0;

  /* Size */
  height: 56px;
  min-height: 56px;
  max-width: 280px;
}
