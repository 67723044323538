@import '../../styles/customMediaQueries.css';

.root {
}

.sections {
  margin: 0;
}

.section {
  position: relative;
}

.sectionContent {
  margin: 48px 24px;

  @media (--viewportMedium) {
    max-width: 100%;
  }

  @media (--viewportLarge) {
    max-width: 1180px;
    padding: 0 36px 0 36px;
    margin: 72px auto;
  }

  @media (--viewportXLarge) {
    max-width: 1128px;
    padding: 0;
  }
}

.sectionContentFirstChild {
  composes: sectionContent;
  margin-top: 24px;
  overflow: hidden;

  @media (--viewportLarge) {
    margin: 24px auto 72px;
  }
}

.sectionContentLastChild {
  composes: sectionContent;
  margin-bottom: 0;
}

/* Full width content */
.sectionContentFullWidth {
  max-width: 100%;
  padding: 0;

  /* Margin */
  margin-left: 0;
  margin-right: 0;
}

.fullWidthWrapper {
  @media (--viewportMedium) {
    max-width: 100%;
  }

  @media (--viewportLarge) {
    max-width: 1180px;
    margin: 0 auto;
    padding: 0 24px;
  }

  @media (--viewportXLarge) {
    max-width: 1128px;
  }
}

/* LandingPage sections */
.sectionIntro {
  background: linear-gradient(
    90deg,
    rgb(205, 255, 216) 0%,
    rgb(148, 185, 255) 35%,
    rgb(148, 185, 255) 50%,
    rgb(148, 185, 255) 100%
  );

  @media (--viewportMedium) {
    background: linear-gradient(
      90deg,
      rgb(205, 255, 216) 0%,
      rgb(148, 185, 255) 50%,
      rgb(148, 185, 255) 100%
    );
  }
}

.sectionServices {
  background-color: var(--landingPageServicesMainColor);
}

.sectionSellOnPetspace {
  background-color: var(--landingPageSellOnPetspaceMainColor);
}
