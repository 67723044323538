@import '../../../styles/customMediaQueries.css';

.title {
  composes: h1 from global;
  composes: title from global;

  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
}

.filteredSearches {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 32px;
  justify-content: center;
  gap: 24px;
}

.searchLink {
  width: 39vw;
  margin-top: 25px;
  text-align: center;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }

  @media (--viewportMedium) {
    width: 11vw;
  }
}

/* A wrapper for a responsive image that holds it's aspect ratio */
.imageWrapper {
  position: relative;
  width: 100%;

  min-width: 39vw;
  min-height: 39vw;

  border-radius: 50%;
  border: 2px solid var(--marketplaceColor);
  overflow: hidden;

  transition: var(--transitionStyleButton);

  &:hover {
    transform: scale(1.02);
    box-shadow: var(--boxShadowSectionLocationHover);
  }

  @media (--viewportMedium) {
    min-width: 11vw;
    min-height: 11vw;
  }
}

/* An image wrapper that defines the images aspect ratio */
.aspectWrapper {
  padding-bottom: calc(6 / 13 * 100%); /* 13:6 Aspect Ratio */

  @media (--viewportMedium) {
    padding-bottom: calc(2 / 3 * 100%); /* 3:2 Aspect Ratio */
  }
}

.searchImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  object-fit: contain;
}

.linkText {
  composes: h2 from global;
  color: var(--matterColor);
  margin-top: 15px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 21px;
  }
}

.searchName {
  color: var(--airgoodsBlack);
  text-transform: capitalize;
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
}
