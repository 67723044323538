@import '../../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  /* Font */
  text-align: center;

  /* Style */
  background-color: var(--landingPageHeroMainColor);
  border-radius: 24px;

  /* Margin & Padding */
  margin: 24px 24px 0;
  padding: 32px 24px;
}

.title {
  /* Font */
  font-family: var(--fontFamilyFutura);
  font-weight: var(--fontWeightBold);
  color: var(--landingPageHeroSecondaryColor);

  margin-top: 0;
  margin-bottom: 24px;
}

.text {
  /* Font */
  color: var(--landingPageHeroSecondaryColor);
  font-weight: var(--fontWeightSemiBold);

  margin-top: 12px;
  margin-bottom: 0;
}

.highlightedText {
  /* Font */
  font-family: var(--fontFamilyFabrica);
  font-weight: var(--fontWeightBold);
  color: var(--landingPageHeroHighlightedTextColor);
}

.linkButton {
  composes: button buttonFont buttonText buttonBorders buttonColorsPrimary from global;

  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Style */
  background-color: var(--landingPageHeroSecondaryColor);
  border: 2px solid var(--landingPageHeroSecondaryColor);
  border-radius: 30px;
  padding: 0;

  /* Size */
  height: 56px;
  min-height: 56px;
  max-width: 280px;
}
